import React from "react"
import { Link } from "gatsby"
import LazyLoad from 'react-lazyload';

import './card.css';

const Card = (article) => {
  return (
    <div className={article.odd ? 'card card__odd': 'card'}>
      <Link to={`/blog/${article.slug}`} className="card__image">
        <span>
          <LazyLoad>
          <img 
            src={`${article.Content[0].image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')}`} 
            alt={article.Content[0].image?.alternativeText} 
            width={article.Content[0].image?.width}
            height={article.Content[0].image?.height} />
          </LazyLoad>
        </span>
      </Link>
      <div className="card__content">
        <h2 className="card__title" dangerouslySetInnerHTML={{__html:article.title}}></h2>
        <p>{article.shortText}</p>
        <p>
          <Link to={`/blog/${article.slug}`} className="card__button" >Read More</Link>
        </p>
      </div>
    </div>
  )
}

export default Card
