import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Articles from "../components/articles/articles"
import HeroImage from "../components/hero-image/hero-image"
import FullWidthBook from "../components/full-width-book/full-width-book"
import { Helmet } from "react-helmet"

export const query = graphql`
query {
    strapi {
      pages(where: {slug: "blog"}) {
        id
        slug
        meta_description
        meta_robots
        og_fb_image {
            url
        }
        og_twitter_image {
            url
        }
        Title
        Content {
            ... on Strapi_ComponentPageComponentsHeroImage {
                id
                image {
                    url
                }
                Title
            }
            ... on Strapi_ComponentPageComponentsFullWidthBook {
              id
              social_title
              book_title
              book_subtitle
              book_button_link
              book_button_text
              image {
                url
              }
            }
        }
      }
      blogArticles {
          id
          title
          slug
          shortText

          Content {
            ... on Strapi_ComponentPageComponentsHeroImage {
                image {
                    url
                    alternativeText
                }
            }
          }
      }
    }
  }
  
`
const Page = ({ data }) => {
    const {pages, page, blogArticles: articles} = data.strapi;
    const {
        meta_description,
        meta_robots,
        og_fb_image,
        og_twitter_image,
        slug,
        Title
    } = pages[0];
  return (
    <>
    <Helmet
        title={Title}
        link={[
            {
              rel: "stylesheet",
              href: "https://fonts.googleapis.com/css?family=Teko",
            },
            {
              rel: "stylesheet",
              href: "https://fonts.googleapis.com/css?family=Oswald",
            }
        ]}
        meta={[
            {
            name: `description`,
            content: meta_description,
            },
            {
            property: `og:title`,
            content: Title,
            },
            {
                property: 'robots',
                content: meta_robots
            },
            {
            property: `og:description`,
            content: meta_description,
            },
            {
            property: `og:type`,
            content: `website`,
            },
            {
            property: `og:url`,
            content: `http://jamhot.band/${slug ? slug: ''}`,
            },
            {
            property: `og:image`,
            content: og_fb_image?.url,
            },
            {
              property: `og:image:width`,
              content: 1200,
            },
            {
              property: `og:image:height`,
              content: 630,
            },
            {
            name: `twitter:card`,
            content: `summary_large_image`,
            },
            {
            name: `twitter:title`,
            content: Title,
            },
            {
            name: `twitter:description`,
            content: meta_description,
            },
            {
            property: `twitter:url`,
            content: `http://jamhot.band/${slug}`,
            },
            {
            property: `twitter:image`,
            content: og_twitter_image?.url,
            },
            {
              property: `twitter:image:width`,
              content: 1200,
            },
            {
              property: `twitter:image:height`,
              content: 630,
            },
        ].concat()}
    ></Helmet>
    <Layout>
        <HeroImage {...pages[0].Content[0]} />
        <Articles articles={articles} />
        <FullWidthBook {...pages[0].Content[1]} />
    </Layout>
    </>
  )
}

export default Page
