import React from "react"
import Card from "../card/card"
import './articles.css';

const Articles = ({ articles }) => {

  return (
    <div className="articles container">
      {articles && articles.map((article, index) => (
        <Card key={article.id} odd={index%2} {...article} />
      ))}
    </div>
  )
}

export default Articles
